import { authHeader } from "./auth-header";
import axios from "../axios";
import VueJwtDecode from "vue-jwt-decode";

export const userService = {
  login,
  logout,
  register,
  getAll,
  getModules,
};

function login(username, password) {
  return axios
    .post("login", { user: username, password: password })
    .then((response) => {
      if (response.data.status == "success") {
        if (response.data.token) {
          let dataDecoded = VueJwtDecode.decode(response.data.token);
          let user = {
            token: response.data.token,
            userName: dataDecoded.userName,
            email: dataDecoded.email,
          };

          localStorage.setItem("user", JSON.stringify(user));
          return user;
        }
      } else {
        console.log(response.data.status);
        const error = response.data.message || response.statusText;
        return Promise.reject(error);
      }
    });

  /* return fetch(`/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        }); */
}

function getModules(username) {
  return axios
    .get("v2/modules", {
      params: {
        user: username,
      },
    })
    .then((response) => {
      if (response.data.status == "success") {
        if (response.data.data) {
            let modules = response.data.data;
        
            localStorage.setItem("modules", JSON.stringify(modules));
          return modules;
        }
      } else {
        const error = response.data.message || response.statusText;
        return Promise.reject(error);
      }
    });

  /* return fetch(`/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        }); */
}

function logout() {
  // remove user from local storage to log user out
  console.log("vassss a eliminar del localStorage");
  localStorage.removeItem("user");
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
