<template>
 <router-view></router-view>
</template>

<script>
import { authComputed } from "@/state/helpers";
export default {
  name: 'App',
  components: {
  },
  computed: {
    ...authComputed
  },
  mounted() {
    /* this.$store.dispatch('user/cargarModulos', this.currentUser.userName, { root: true }); */
  },
}
</script>

