import { createWebHistory, createRouter } from "vue-router";

import store from "@/state/store";
import routes from "./routes";
import VueJwtDecode from "vue-jwt-decode";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ["/login", "/register", "/forgot-password"];
  const authpage = !publicPages.includes(routeTo.path);
  const loggeduser = localStorage.getItem("user");

  if (authpage && !loggeduser) {
    return next("/login");
  }

  //ahora valido la expiracion
  const user = JSON.parse(localStorage.getItem("user")); // obtiene el token del almacenamiento local
  if (user) {
    const tokenValido = user.token && !tokenExpirado(user.token); // comprueba si el token es válido

    if (authpage && !tokenValido) {
      // la ruta requiere autenticación y el token ha expirado, redirigir al usuario a la página de inicio de sesión
      return next("/login");
    }
  }

  const requiredModule = routeTo.meta.requiresModule;
  // Si la ruta no requiere un módulo específico, continúa
  if (!requiredModule) {
    next();
    return;
  }
  // Verifica si el usuario tiene acceso al módulo requerido
  if (store.getters["user/hasPermissionModule"](requiredModule)) {
    // El usuario tiene acceso al módulo, continúa
    next();
  } else {
    // El usuario no tiene acceso al módulo, redirige a una ruta de error o muestra un mensaje
    next("/error");
  }

  next();
});

function tokenExpirado(token) {
  console.log(token);
  const { exp } = VueJwtDecode.decode(token);
  const ahora = Date.now() / 1000; // convierte a segundos
  return ahora > exp;
}

export default router;
