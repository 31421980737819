import { userService } from "../../helpers/authservice/user.service";

export const state = {
  modulos: [], // Array para almacenar los módulos disponibles del usuario
};

export const mutations = {
  SET_MODULOS(state, modulos) {
    state.modulos = modulos;
  },
};

export const getters = {
  getModulos: (state) => state.modulos,
  hasPermissionModule: (state) => (modulo) => {
    // Verificar si el usuario tiene permiso para acceder al módulo
    let modules = state.modulos.length === 0 ? JSON.parse(localStorage.getItem('modules')) : state.modulos;
    return modules.some((obj) => obj.nombre === modulo);
  },
};

export const actions = {
  cargarModulos({ dispatch, commit }, username) {
    userService.getModules(username).then(
      (modules) => {
        commit("SET_MODULOS", modules);
      },
      (error) => {
        dispatch("notification/error", error, { root: true });
      }
    );
  },
};
